<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('list_copytrade_account')">
      <b-row>
        <b-col
          cols="12"
          class=""
        >
          <b-alert
            show
            variant="success"
            class="p-2"
          >
            <h5>
              {{ $t('list_copytrade_account_title') }}
            </h5>
          </b-alert>
        </b-col>
        <b-col
          cols="12"
          class="mb-3 text-warning"
        >
          <a @click="openInfoModal">
            {{ $t('list_copytrade_account_description') }}
          </a>
        </b-col>
        <b-col cols="12">
          <b-row class="d-flex align-items-center">
            <b-col
              md="4"
              lg="4"
            >
              <b-form-group
                :label="$t('strategy_name')"
                label-for="strategy_name"
              >
                <b-form-input
                  id="strategy_name"
                  v-model="strategy_name"
                  :placeholder="$t('strategy_name')"
                />
              </b-form-group>
            </b-col>
            <b-col class="">
              <b-button
                variant="success"
                @click="search"
              >
                {{ $t('search') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="accounts.length > 0"
          cols="12"
        >
          <b-overlay
            :show="accountsShow"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-row>
              <b-col
                v-for="account in accounts"
                :key="account.id"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <AccountCard
                  :account-info="account"
                  :accounts="metatraderAccounts"
                />
              </b-col>
            </b-row>
          </b-overlay>
          <b-row
            v-if="pagination.total > pagination.per_page"
            align-h="center"
            class="mt-2"
          >
            <b-col cols="auto">
              <b-pagination
                v-model="pagination.current_page"
                :total-rows="pagination.total"
                :per-page="pagination.per_page"
                size="sm"
                @input="changePage()"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="accounts.length === 0 && !accountsShow">
          <h5 class="text-center py-3 text-danger">
            {{ $t('no_account_to_show') }}
          </h5>
        </b-col>
      </b-row>

      <b-modal
        id="infoModal"
        v-model="infoModalShow"
        centered
        hide-footer
        size="lg"
        :title="$t('copytrade_info.further_details')"
      >
        <p>
          <span style="font-weight : 500">{{ $t('copytrade_info.percentage_trades_closed_profit') }}</span>
          <span class="mx-1">:</span>
          <span>{{ $t('copytrade_info.percentage_trades_closed_profit_description') }}</span>
        </p>
        <p>
          <span style="font-weight : 500">{{ $t('copytrade_info.average_risk') }}</span>
          <span class="mx-1">:</span>
          <span>{{ $t('copytrade_info.average_risk_description') }}</span>
        </p>
        <p>
          <span style="font-weight : 500">{{ $t('copytrade_info.profit_coefficient') }}</span>
          <span class="mx-1">:</span>
          <span>{{ $t('copytrade_info.profit_coefficient_description') }}</span>
        </p>
        <p>
          <span style="font-weight : 500">{{ $t('copytrade_info.growth_score') }}</span>
          <span class="mx-1">:</span>
          <span>{{ $t('copytrade_info.growth_score_description') }}</span>
        </p>
        <p>
          <span style="font-weight : 500">{{ $t('copytrade_info.biggest_loss') }}</span>
          <span class="mx-1">:</span>
          <span>{{ $t('copytrade_info.biggest_loss_description') }}</span>
        </p>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BPagination,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import AccountCard from '@/modules/copytrade/views/components/account.vue'
import TradecopyApis from '@/modules/copytrade/service/apis/index'

const tradecopyApis = new TradecopyApis()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BPagination,
    AccountCard,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      show: false,
      accountsShow: false,
      infoModalShow: false,
      accounts: [],
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 30,
      },
      metatraderAccounts: [],
      strategy_name: '',
    }
  },
  mounted() {
    this.getAccounts()
    this.getMetatraderAccounts()
  },
  methods: {
    getAccounts() {
      this.accountsShow = true
      tradecopyApis.getAll({
        page: this.pagination.current_page,
        per_page: this.pagination.per_page,
        strategy_name: this.strategy_name,
      }).then(res => {
        this.accounts = res.data.results.data.map(item => ({
          ...item,
          account_platform: item.master.account.platform,
          account_group: item.master.account.group,
        }))
        this.pagination.total = res.data.results.pagination.total
      }).finally(() => {
        this.accountsShow = false
      })
    },
    search() {
      this.pagination.current_page = 1
      this.getAccounts()
    },
    openInfoModal() {
      this.infoModalShow = true
    },
    searchAccount() {
      this.pagination.current_page = 1
      this.getAccounts()
    },
    changePage() {
      this.getAccounts()
    },
    getMetatraderAccounts() {
      this.accountsShow = true
      tradecopyApis.getAccounts().then(res => {
        this.metatraderAccounts = res.data.results
      }).finally(() => {
        this.accountsShow = false
      })
    },
  },
}
</script>
