<template>
  <div
    class="copytrade_account_card"
    style="background-color: #FAFAFB;"
    :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark' : ' bg-white'"
  >
    <b-row
      class=""
      style="padding: 5px;"
    >
      <b-col
        cols="12"
        class="text-center rounded py-1"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark' : ' bg-white'"
      >
        <span style="font-size: 20px; font-weight: 700">{{ accountInfo.master.strategy_name }}</span>
      </b-col>
      <b-col
        cols="12"
        style="justify-content: space-around; padding: 14px 0;"
        class="text-center mt-2 rounded d-flex  align-items-center"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark text-white' : ' bg-white text-primary'"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="fee_text">
            {{ $t('fixed_monthly_subscription_fee') }}
          </div>
          <div
            class="fee_num"
            dir="ltr"
          >
            <span>$</span>
            <span>{{ accountInfo.master.monthly_fee }}</span>
          </div>
        </div>
        <div style="width:1px; height: 80%; background-color: rgb(233 233 233)" />
        <div>
          <div class="fee_text">
            {{ $t('percentage_participation_traders_profit') }}
          </div>
          <div
            class="fee_num"
            dir="ltr"
          >
            <span>%</span>
            <span>{{ accountInfo.master.profit_percent }}</span>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        style="justify-content: space-around; padding: 14px 0; margin-top: 6px;"
        class="text-center rounded d-flex text-success align-items-center"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark' : ' bg-white'"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="fee_text">
            {{ $t('copytrade_info.highest_amount_profit') }}
          </div>
          <div
            class="fee_num"
            dir="ltr"
          >
            <span>$</span>
            <span>{{ accountInfo.total_profit.toFixed(2) }}</span>
          </div>
        </div>
        <div style="width:1px; height: 80%; background-color: rgb(233 233 233)" />
        <div class="text-danger">
          <div class="fee_text">
            {{ $t('copytrade_info.maximum_amount_damage') }}
          </div>
          <div
            class="fee_num"
            dir="ltr"
          >
            <span>$</span>
            <span>{{ accountInfo.total_loss.toFixed(2) }}</span>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        style="justify-content: space-around; padding: 14px 0; margin-top: 6px;"
        class="text-center rounded d-flex align-items-center"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark text-white' : ' bg-white text-primary'"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="fee_text">
            {{ $t('copytrade_info.closed_trade_with_profit') }}
          </div>
          <div
            class="fee_num"
            dir="ltr"
          >
            <span>%</span>
            <span>{{ accountInfo.closed_profit_percent.toFixed(2) }}</span>
          </div>
        </div>

      </b-col>
      <b-col
        class="mt-2 p-1 rounded"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'copytrade_account_card_dark' : ' bg-white'"
        cols="12"
      >
        <b-row
          v-for="item in cardItems"
          :key="item.name"
        >
          <b-col
            cols="1"
            style="margin: 4px 0"
            class="text-primary"
          >
            <feather-icon
              icon="CheckIcon"
              size="14"
            />
          </b-col>
          <b-col
            cols="5"
          >
            {{ $t(`copytrade_info.${item.name}`) }}
          </b-col>
          <b-col
            cols="auto"
            dir="ltr"
          >
            <span v-if="(item.value === 'equity') || item.value === 'balance'">
              {{ '$'+accountInfo[item.value].toFixed(2) }}</span>
            <span v-else> {{ accountInfo[item.value] }}</span>
          </b-col>
          <div>
            <div v-if="(item.value === 'age')">
              {{ $t('week') }}</div>

          </div>
          <div v-if="(item.value === 'followers')">
            {{ $t('person') }}</div>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="p-0"
      >
        <b-button
          variant="primary"
          block
          class="mt-2"
          @click="openFollowModal"
        >
          {{ $t('follow') }}
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      :id="`following-modal-${accountInfo.id}`"
      v-model="followingModalShow"
      centered
      hide-footer
      size="lg"
      :title="accountInfo.name"
    >
      <validation-observer v-slot="{ invalid }">
        <b-form>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('metatrader_account')"
                label-for="metatrader_account"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('metatrader_account')"
                  rules="required"
                >
                  <v-select
                    id="metatrader_account"
                    v-model="selectedAccount"
                    :options="accounts"
                    :placeholder="$t('metatrader_account')"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div>
                        <span>{{ option.account }}</span>
                        <span class="mx-1">({{ option.platform }} - {{ option.group }})</span>
                      </div>
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      <div>
                        <span>{{ option.account }}</span>
                        <span class="mx-1">({{ option.platform }} - {{ option.group }})</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <p
                    style="font-size:12px"
                    class="text-warning"
                  >
                    {{ $t('copytrade_info.choose_account_info') }}
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('copytrade_info.operation_factor')"
                label-for="copytrade_info.operation_factor"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('copytrade_info.operation_factor')"
                  rules="required"
                >
                  <b-form-input
                    v-model="operationFactor"
                    name="strategy"
                    :placeholder="$t('copytrade_info.operation_factor')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                :label="$t('agreement_text')"
                label-for="agreement_text"
              >
                <div
                  style="
                        height: 20rem;
                        overflow: scroll;
                        background-color: #ddd;
                        direction: ltr;
                        text-align: justify;
                        padding: 10px;
                      "
                  v-html="agreement"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="isAgree"
                  v-model="isAgree"
                  name="isAgree"
                >
                  {{ $t('isAgreeAgreement') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                :disabled="!isAgree || invalid"
                @click="followAccount"
              >
                <span v-if="!followPending">
                  {{ $t('follow') }}
                </span>
                <span v-else>
                  <b-spinner
                    small
                    type="grow"
                  />
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, numeric } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TradecopyApis from '@/modules/copytrade/service/apis/index'
import { toFixed } from '@/utils'

const tradecopyApis = new TradecopyApis()

export default {
  components: {
    BButton,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    accountInfo: {
      type: Object,
      required: true,
    },
    accounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      followingModalShow: false,
      required,
      numeric,
      cardItems: [
        { name: 'acount_lifetime', value: 'age' },
        { name: 'equity', value: 'equity' },
        { name: 'account_balance', value: 'balance' },
        { name: 'followers', value: 'followers' },
        { name: 'platform', value: 'account_platform' },
        { name: 'group', value: 'account_group' },
      ],
      operationFactor: '',
      followPending: false,
      isAgree: false,
      selectedAccount: null,
      accountOptions: {},
      agreement: '',
      toFixed,
    }
  },
  methods: {
    followAccount() {
      const data = {
        master_id: this.accountInfo.master.id,
        account_id: this.selectedAccount.id,
        multiplier: this.operationFactor,
      }
      this.followPending = true
      tradecopyApis.followAccount(data)
        .then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.followingModalShow = false
          this.operationFactor = ''
        }).finally(() => {
          this.followPending = false
        })
    },
    openFollowModal() {
      this.followingModalShow = true
      tradecopyApis.getAgreementContent(this.accountInfo.master.agreement_id).then(res => {
        this.agreement = res.data.results
      })
    },
  },
}
</script>
<style>
.copytrade_account_card{
  box-shadow: 0 2px 14px 0 rgba(87, 87, 87, 0.15) !important;
  margin: 16px 6px;
  padding: 16px 24px;
  border-radius: 6px;
}
.copytrade_account_card_dark{
background-color: #1c294c !important;
}
.fee_text{
  font-size: .9rem;
  /* margin-bottom: 6px */
}
.fee_num{
  font-size: 1.1rem;
  font-weight:700
}

@media (max-width: 600px) {
  .fee_text{
    font-size: .8rem;
  }
}
</style>
